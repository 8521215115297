import React from 'react';
import Register from '../components/Register';
import Header from '../components/landingPage/Header';


const RegisterPage = () => (
  <div>
    <Header />
    <Register />
  </div>
);

export default RegisterPage;
