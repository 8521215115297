// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* File: src/components/Footer.css */
.footer {
    padding: 2rem 2rem;
    background-color: #282c34;
    color: white;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer p {
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  
  .social-icons {
    display: flex;
    gap: 1rem;
  }
  
  .social-icons a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: #ff7f50;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/landingPage/Footer.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,SAAS;EACX;;EAEA;IACE,YAAY;IACZ,qBAAqB;IACrB,iBAAiB;IACjB,2BAA2B;EAC7B;;EAEA;IACE,cAAc;EAChB","sourcesContent":["/* File: src/components/Footer.css */\n.footer {\n    padding: 2rem 2rem;\n    background-color: #282c34;\n    color: white;\n    text-align: center;\n  }\n  \n  .footer-content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  \n  .footer p {\n    margin-bottom: 1rem;\n    font-size: 1rem;\n  }\n  \n  .social-icons {\n    display: flex;\n    gap: 1rem;\n  }\n  \n  .social-icons a {\n    color: white;\n    text-decoration: none;\n    font-size: 1.5rem;\n    transition: color 0.3s ease;\n  }\n  \n  .social-icons a:hover {\n    color: #ff7f50;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
