// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* File: src/components/CTASection.css */
.cta {
    padding: 4rem 2rem;
    background-color: #ff7f50;
    text-align: center;
    color: white;
  }
  
  .cta h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .cta-button {
    padding: 0.75rem 2rem;
    font-size: 1rem;
    background-color: white;
    color: #ff7f50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cta-button:hover {
    background-color: #fff5f0;
    color: #ff6347;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/landingPage/CTASection.css"],"names":[],"mappings":"AAAA,wCAAwC;AACxC;IACI,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,eAAe;IACf,uBAAuB;IACvB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,cAAc;EAChB","sourcesContent":["/* File: src/components/CTASection.css */\n.cta {\n    padding: 4rem 2rem;\n    background-color: #ff7f50;\n    text-align: center;\n    color: white;\n  }\n  \n  .cta h2 {\n    font-size: 2.5rem;\n    margin-bottom: 2rem;\n  }\n  \n  .cta-button {\n    padding: 0.75rem 2rem;\n    font-size: 1rem;\n    background-color: white;\n    color: #ff7f50;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .cta-button:hover {\n    background-color: #fff5f0;\n    color: #ff6347;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
