// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* File: src/components/FeaturesSection.css */
.features {
  padding: 4rem 2rem;
  background-color: #f0f8ff;
  text-align: center;
}

.features h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #2c3e50;
}

.feature-cards {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.feature-card {
  background-color: white;
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 320px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature-card h3 {
  font-size: 1.7rem;
  margin-bottom: 1rem;
  color: #16a085;
}

.feature-card p {
  font-size: 1rem;
  color: #555;
}

.feature-card .emoji {
  font-size: 3rem;
  margin-bottom: 1.5rem;
}


.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
}

`, "",{"version":3,"sources":["webpack://./src/components/landingPage/FeaturesSection.css"],"names":[],"mappings":"AAAA,6CAA6C;AAC7C;EACE,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,mBAAmB;EACnB,yCAAyC;EACzC,WAAW;EACX,gBAAgB;EAChB,qDAAqD;EACrD,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,qBAAqB;AACvB;;;AAGA;EACE,4BAA4B;EAC5B,0CAA0C;AAC5C","sourcesContent":["/* File: src/components/FeaturesSection.css */\n.features {\n  padding: 4rem 2rem;\n  background-color: #f0f8ff;\n  text-align: center;\n}\n\n.features h2 {\n  font-size: 2.5rem;\n  margin-bottom: 2rem;\n  color: #2c3e50;\n}\n\n.feature-cards {\n  display: flex;\n  justify-content: center;\n  gap: 2rem;\n  flex-wrap: wrap;\n}\n\n.feature-card {\n  background-color: white;\n  padding: 2.5rem;\n  border-radius: 15px;\n  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);\n  width: 100%;\n  max-width: 320px;\n  transition: transform 0.3s ease, box-shadow 0.3s ease;\n  text-align: left;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.feature-card h3 {\n  font-size: 1.7rem;\n  margin-bottom: 1rem;\n  color: #16a085;\n}\n\n.feature-card p {\n  font-size: 1rem;\n  color: #555;\n}\n\n.feature-card .emoji {\n  font-size: 3rem;\n  margin-bottom: 1.5rem;\n}\n\n\n.feature-card:hover {\n  transform: translateY(-10px);\n  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
