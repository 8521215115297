// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* File: src/components/HeroSection.css */
.hero {
    background: url('https://images.unsplash.com/photo-1513378628213-b8f36d8c2878?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') no-repeat center center/cover;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hero-content {
    text-align: center;
    color: white;
    max-width: 600px;
  }
  
  .hero-content h1 {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
  
  .hero-content p {
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }
  
  .cta-button {
    padding: 0.75rem 2rem;
    font-size: 1rem;
    background-color: #ff7f50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cta-button:hover {
    background-color: #ff6347;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/landingPage/HeroSection.css"],"names":[],"mappings":"AAAA,yCAAyC;AACzC;IACI,uNAAuN;IACvN,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* File: src/components/HeroSection.css */\n.hero {\n    background: url('https://images.unsplash.com/photo-1513378628213-b8f36d8c2878?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') no-repeat center center/cover;\n    height: 90vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .hero-content {\n    text-align: center;\n    color: white;\n    max-width: 600px;\n  }\n  \n  .hero-content h1 {\n    font-size: 4rem;\n    margin-bottom: 1rem;\n  }\n  \n  .hero-content p {\n    font-size: 1.7rem;\n    margin-bottom: 2rem;\n  }\n  \n  .cta-button {\n    padding: 0.75rem 2rem;\n    font-size: 1rem;\n    background-color: #ff7f50;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .cta-button:hover {\n    background-color: #ff6347;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
