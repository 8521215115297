import React from 'react';
import Exercise from '../components/Exercise';
import Header from '../components/landingPage/Header';


const ExercisePage = () => {


  return (
    <div>
      <Exercise />
    </div>
  );
};

export default ExercisePage;
