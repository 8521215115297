// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* File: src/components/TestimonialsSection.css */
.testimonials {
  padding: 4rem 2rem;
  background-color: #fff;
  text-align: center;
}

.testimonials h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #2c3e50;
}

.testimonial-cards {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.testimonial-card {
  background-color: #f0f8ff;
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 320px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
}

.testimonial-card p {
  font-size: 1rem;
  font-style: italic;
  color: #2c3e50;
}

.testimonial-card h4 {
  font-size: 1.1rem;
  margin-top: 1.5rem;
  font-weight: bold;
  color: #16a085;
}
`, "",{"version":3,"sources":["webpack://./src/components/landingPage/TestimonialsSection.css"],"names":[],"mappings":"AAAA,iDAAiD;AACjD;EACE,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,mBAAmB;EACnB,yCAAyC;EACzC,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,qDAAqD;AACvD;;AAEA;EACE,4BAA4B;EAC5B,0CAA0C;AAC5C;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":["/* File: src/components/TestimonialsSection.css */\n.testimonials {\n  padding: 4rem 2rem;\n  background-color: #fff;\n  text-align: center;\n}\n\n.testimonials h2 {\n  font-size: 2.5rem;\n  margin-bottom: 2rem;\n  color: #2c3e50;\n}\n\n.testimonial-cards {\n  display: flex;\n  justify-content: center;\n  gap: 2rem;\n  flex-wrap: wrap;\n}\n\n.testimonial-card {\n  background-color: #f0f8ff;\n  padding: 2.5rem;\n  border-radius: 15px;\n  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);\n  width: 100%;\n  max-width: 320px;\n  text-align: left;\n  transition: transform 0.3s ease, box-shadow 0.3s ease;\n}\n\n.testimonial-card:hover {\n  transform: translateY(-10px);\n  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);\n}\n\n.testimonial-card p {\n  font-size: 1rem;\n  font-style: italic;\n  color: #2c3e50;\n}\n\n.testimonial-card h4 {\n  font-size: 1.1rem;\n  margin-top: 1.5rem;\n  font-weight: bold;\n  color: #16a085;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
