// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
body {
  font-family: 'Georgia', serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

header {
  background-color: #222;
  color: white;
  padding: 1rem;
  text-align: center;
}

header nav ul {
  list-style-type: none;
  padding: 0;
}

header nav ul li {
  display: inline;
  margin: 0 1rem;
}

header nav ul li button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1rem;
}

h1 {
  font-size: 2rem;
  text-align: center;
  margin-top: 2rem;
}

/* form {
  max-width: 600px;
  margin: 2rem auto;
  padding: 1rem;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

/* input {
  display: block;
  width: calc(100% - 2rem);
  margin: 0.5rem 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
} */

/* button {
  display: block;
  width: calc(100% - 2rem);
  margin: 1rem;
  padding: 0.75rem;
  background-color: #222;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
} */
/* 
button:hover {
  background-color: #555;
} */

/* h3 {
  font-size: 1.5rem;
  text-align: center;
}

p {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0 1rem;
} */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,6BAA6B;EAC7B,yBAAyB;EACzB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;;;;;;;GAOG;;AAEH;;;;;;;GAOG;;AAEH;;;;;;;;;;GAUG;AACH;;;GAGG;;AAEH;;;;;;;;;GASG","sourcesContent":["/* styles.css */\nbody {\n  font-family: 'Georgia', serif;\n  background-color: #f4f4f4;\n  margin: 0;\n  padding: 0;\n}\n\nheader {\n  background-color: #222;\n  color: white;\n  padding: 1rem;\n  text-align: center;\n}\n\nheader nav ul {\n  list-style-type: none;\n  padding: 0;\n}\n\nheader nav ul li {\n  display: inline;\n  margin: 0 1rem;\n}\n\nheader nav ul li button {\n  background: none;\n  border: none;\n  color: white;\n  cursor: pointer;\n  font-size: 1rem;\n}\n\nh1 {\n  font-size: 2rem;\n  text-align: center;\n  margin-top: 2rem;\n}\n\n/* form {\n  max-width: 600px;\n  margin: 2rem auto;\n  padding: 1rem;\n  background: white;\n  border: 1px solid #ddd;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n} */\n\n/* input {\n  display: block;\n  width: calc(100% - 2rem);\n  margin: 0.5rem 1rem;\n  padding: 0.5rem;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n} */\n\n/* button {\n  display: block;\n  width: calc(100% - 2rem);\n  margin: 1rem;\n  padding: 0.75rem;\n  background-color: #222;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n} */\n/* \nbutton:hover {\n  background-color: #555;\n} */\n\n/* h3 {\n  font-size: 1.5rem;\n  text-align: center;\n}\n\np {\n  font-size: 1rem;\n  line-height: 1.5;\n  margin: 0 1rem;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
